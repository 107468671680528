 <template>
    <div class="w">
		<con-tab ></con-tab>
       <div class="details-one">
        <div class="one-image">
          <img
            :alt="XQ.name"
            class="one-image"
            :data-src="urlImg+XQ.img"
            :src="urlImg+XQ.img"
            lazy="loaded"
          />
        </div>
        <div class="one-info">
          <div class="info-title">{{ XQ.name }}</div>
          <div class="info-time">
            <span>活动时间：</span>
            <span
              >{{ XQ.created_at | formatDate }}  </span
            >
          </div>
            <div class="info-time">
            <span>服务响应时间：</span>
            <span
              >{{ XQ.ftime}}  </span
            >
          </div>
            <div class="info-time">
            <span>服务费用：</span>
            <span
              >{{ XQ.costs }}元  </span
            >
          </div>
            <div class="info-time">
            <span>联系方式：</span>
            <span
              >{{ XQ.phone }}  </span
            >
          </div>
          <!-- <div class="info-time">
            <span>活动地点：</span>
            <span
              >{{ XQ.province }}{{ XQ.city }}{{ XQ.area
              }}{{ XQ.address_detail }}</span
            >
          </div> -->
          <!-- <button class="info-limit">限额{{ XQ.num }}人</button>
          <div class="info-last">
            <div class="left">
              <el-button
                class="info-paoming"
                type="primary"
                 @click="pm"
                >我要报名</el-button
              >
    
              <span
                >已有<i style="color: #ff4a29">{{ num }}</i
                >人报名</span
              >
            </div>
            <div class="right collect-btn">
              <span class="info-view"
                ><i class="el-icon-view"></i>浏览量：{{ XQ.views ?XQ.views :0}}</span
              >
            </div>
          </div> -->
        </div>
      </div>
      <div class="details-two">
        <h3 class="two-title">活动内容</h3>
        <div class="two-content">
          <div class="two-content-info">
            <div class="inner-content-info">
              <h3>{{ XQ.name }}</h3>

              <p v-html="XQ.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
 
</template>

<script>
	import ConTab from '../../../components/common/ConTab';
import { InnovateXq } from "../../../common/js/api";
import { formatDate } from "../../../common/js/times"; //时间
import { urlImg } from "@/common/js/url";

export default {
	components: {
		// HomeSwiper,
		ConTab,
	},
  data() {
    return {
      ID: "",
      XQ: {},
         urlImg: "",
    };
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  created() {
    this.ID = this.$route.query.id;

    this.xq();
    this.urlImg = urlImg();
  },
  methods: {
     
    // 获取科创活动详情
    xq() {
      InnovateXq({
        id: this.ID,
      })
        .then((res) => {
        //   console.log(res);
          this.XQ = res.data
         })
        .catch(() => {});
    },
  
  },
};
</script>

<style lang="less" scoped>

  
  .details-one {
    position: relative;
    // max-width: 1181px;
    height: 300px;
    padding: 28px 107px 48px 327px;
    border-radius: 4px;
    margin: 200px 0 0 100px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    .one-image {
      position: absolute;
      display: inline-block;
      width: 338px;
      height: 288px;
      border-radius: 10px;
      top: -12px;
      left: -48px;
    }
    .one-info {
      .info-title {
        font-size: 18px;
        color: #101010;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 35px;
      }

      .info-time {
        font-size: 14px;
        // line-height: 24px;
        color: #101010;
        margin-bottom: 20px;
      }
      .info-limit {
        padding: 4px 12px;
        font-size: 12px;
        color: #b7b5b5;
        border-radius: 6px;
        background-color: #e3e1e1;
        margin: 15px 0 28px;
      }
      .info-paoming {
        margin-right: 30px;
      }
      .info-view {
        color: #a8a6a6;
        i {
          margin-right: 5px;
        }
      }
    }
  }

  .details-two {
    .two-title {
      font-size: 16px;
      margin-top: 30px;
      color: #3892eb;
      margin-bottom: 20px;
      font-weight: 700;
    }
    .two-content {
    //   max-width: 1292px;
      padding: 25px 85px 40px 70px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      margin-bottom: 50px;
       h3 {
        font-size: 18px;
        margin-bottom: 30px;
        color: #101010;
      }
      p {
        text-indent: 0.2rem;
        color: #000;
        padding-bottom: 20px;
        line-height: 1.5;
      }
    }
  }
 @media screen and (max-width: 1200px) {
 	 .w{
 		 width: 98%;
 	 }
 	 .details-one{
 		 margin-top: 17rem;
 		 padding: 1rem 1rem 0 14rem;
 		 height:20rem;
 	 }
 	 .details-one .one-info .info-title{
 		 font-size: 1.6rem;
 	 }
 	 .details-one .one-info .info-time{
 		 font-size: 1.4rem;
 	 }
 	 .details-one .one-info .info-limit{
 		 font-size: 1.4rem;
 	 }
 	 /deep/.el-button--primary{
 		 font-size: 1.6rem;
 	 }
 	 .left span{
 		 font-size: 1.4rem;
 	 }
 	 .right span{
 	 		 font-size: 1.4rem;
 	 }
 	 .details-two .two-title{
 		 font-size: 2rem;
 	 }
 	 .details-two .two-content p{
 		 font-size: 1.5rem;
 		line-height: 2.5rem;
 	 }
 	 .details-two .two-content h3{
 		 font-size: 2rem;
 		 line-height: 3rem;
 	 }
 	 .details-two .two-content{
 		 padding: 1.55rem 1rem;
 	 }
 }
</style>