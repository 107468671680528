import { render, staticRenderFns } from "./tabXq.vue?vue&type=template&id=02a4ff60&scoped=true&"
import script from "./tabXq.vue?vue&type=script&lang=js&"
export * from "./tabXq.vue?vue&type=script&lang=js&"
import style0 from "./tabXq.vue?vue&type=style&index=0&id=02a4ff60&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a4ff60",
  null
  
)

export default component.exports